import jwt from 'jsonwebtoken'

import { freemiumClient, uplifyAdminClient } from 'lib/api'
import { client } from 'lib/api/instance'
import { RoleType } from 'lib/types/role'

// const client = Client.getInstance()

const AUTH_TOKEN = 'uplify-auth-token'
const USER_ROLE = 'uplify-user-role'

export const UPLIFY_ADMIN_TOKEN = 'uplify-admin-token'

export const AUTH_IS_DEMO = 'is-streamer-demo'
export const AUTH_TOKEN_EXPIRES = 'expires-uplify-auth-token'
export const AUTH_TOKEN_EXPIRES_DURATION =
  process.env.NODE_ENV === 'development' ? 1000 * 10 * 3 : 1000 * 60 * 30

export const getRole = () => localStorage.getItem(USER_ROLE)

export const getToken = () => localStorage.getItem(AUTH_TOKEN)

export const setToken = (token: string, userRole: RoleType) => {
  const authToken = `Bearer ${token}`
  localStorage.setItem(AUTH_TOKEN, authToken)
  localStorage.setItem(USER_ROLE, userRole)
  client.setAuthToken(authToken)
}

export const setFreemiumToken = (streamerId: string | number) => {
  freemiumClient.setAuthToken('Token ' + jwt.sign({ streamerId }, process.env.VUE_APP_FREEMIUM_SECRET))
}

export const setLinkAdminToken = (token: string) => {
  const authToken = token.includes('Bearer') ? token : `Bearer ${token}`
  localStorage.setItem(UPLIFY_ADMIN_TOKEN, authToken)
  uplifyAdminClient.setAuthToken(authToken)
}

export const removeToken = () => {
  localStorage.removeItem(AUTH_TOKEN)
  localStorage.removeItem(USER_ROLE)
  localStorage.removeItem(AUTH_IS_DEMO)
  localStorage.removeItem(AUTH_TOKEN_EXPIRES)
  localStorage.removeItem(UPLIFY_ADMIN_TOKEN)
  location.href = '/'
}

export const initToken = () => {
  const authToken = getToken()
  const userRole = getRole()

  if (authToken && userRole) {
    client.setAuthToken(authToken)
  }
  else if (authToken) {
    removeToken()
  }
}

export const setDemoToken = () => {
  localStorage.setItem(AUTH_IS_DEMO, '1')
  localStorage.setItem(
    AUTH_TOKEN_EXPIRES,
    (new Date().getTime() + AUTH_TOKEN_EXPIRES_DURATION).toString(),
  )
}

export const isDemoTokenExpired = () => {
  if (localStorage.getItem(AUTH_IS_DEMO)) {
    const currentTime = new Date().getTime()
    const tokenTime = localStorage.getItem(AUTH_TOKEN_EXPIRES) || 0

    return currentTime >= +tokenTime
  }

  return false
}
