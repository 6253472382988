/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 26 20',
    data: '<path pid="0" _fill="red" fill-rule="evenodd" d="M23.149.597c1.123.338 2.009 1.306 2.304 2.532C26 5.355 26 10 26 10s0 4.645-.532 6.871c-.31 1.242-1.182 2.21-2.304 2.532C21.125 20 13 20 13 20s-8.125 0-10.149-.597C1.728 19.065.842 18.097.547 16.871 0 14.645 0 10 0 10s0-4.645.532-6.871C.842 1.887 1.714.919 2.836.597 4.875 0 13 0 13 0s8.125 0 10.149.597zM17.136 10l-6.795 4.226V5.774L17.136 10z" clip-rule="evenodd"/>'
  }
})
